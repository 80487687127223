import React, { useState } from "react"
import styled from "styled-components"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { v4 } from "uuid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import {
  BreadCrumb,
  Section,
  SectionTitle,
  SectionLead,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
  SectionShadowText,
} from "../components/Section"
import { ArrowDownIcon } from "../components/Icons"
import RightLineArrowIcon from "../components/Icons/RightLineArrowIcon"
import DefaultLinkButton from "../components/Button/DefaultLinkButton"
import PrimaryButton from "../components/Button/PrimaryButton"
import BreakpointUp from "../components/Media/BreakpointUp"
import LoanCalculator from "../components/LoanCalculator"
import ApplicationInfoForm from "../components/ApplicationInfoForm"
import PersonalInformationRtoForm from "../components/PersonalInformationRtoForm"
import ListWhiteArrow from "../images/BulletWhiteIcon.svg"
import ListArrow from "../images/BulletIcon.svg"
import ApplyPopup from "../components/ApplyPopup"
import ApplyPopupFinancingForm from "../components/ApplyPopupFinancingForm"
import CustomQuote from "../components/CustomQuote"
import ProductCard from "../components/ProductCard"
import QuotePopup from "../components/QuotePopup"

const BackgroundImage = styled.div`
  ${BreakpointUp.xl` 
    max-width: 1920px;
    width:100%;
    margin:0 auto;
  `}
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
const MarkdownContentRto = styled(MarkdownContent)`
  ul{
    @media (min-width: 768px) {
      column-count:2;
    }
  }
`

const SectionWhite = styled.div`
  position: relative;
  padding: 15px;
  margin-top: -30px;
  ${BreakpointUp.sm`	
      padding:30px;
  `}
  ${BreakpointUp.lg`	
    margin-top:-100px;
    padding:50px;
  `} 
  &:before {
    content: "";
    background-color: #fff;
    position: absolute;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
    top: 0;
    bottom: 80px;
    right: 0;
    left: 0;
  }
`

const CardPrimary = styled.div`
  background-color: #0b619b;
  color: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
`
const CardPrimaryHeading = styled.div`
  display: block;
  position: relative;
  background-image: linear-gradient(
    to right,
    rgba(249, 249, 249, 0.5) 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: center bottom;
  background-size: 9px 1px;
  background-repeat: repeat-x;
  text-align: center;
  padding: 15px;
  ${BreakpointUp.sm`	
    padding:30px;
  `}
  ${BreakpointUp.lg`	
    padding:40px;
  `}
`
const CardPrimaryTitle = styled.div`
  font-family: "Heebo", sans-serif;
  font-weight: 900;
  margin-bottom: 0;
  color: #fff;
  font-size: 28px;
  line-height: 38px;
  @media (min-width: 768px) {
    font-size: 38px;
    line-height: 48px;
  }
  @media (min-width: 992px) {
    font-size: 48px;
    line-height: 58px;
  }
`
const CardPrimaryBody = styled.div`
  padding: 15px;
  ${BreakpointUp.sm`	
    padding:30px;
  `}
  ${BreakpointUp.lg`	
    padding:40px;
  `}
`
const CardPrimaryFooter = styled.div`
  display: block;
  position: relative;
  background-image: linear-gradient(
    to right,
    rgba(249, 249, 249, 0.5) 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: center top;
  background-size: 9px 1px;
  background-repeat: repeat-x;
  text-align: center;
  padding: 15px;
  ${BreakpointUp.sm`	
    padding:30px;
  `}
  ${BreakpointUp.lg`	
    padding:40px;
  `}    
  & .btn {
    border: 4px solid rgba(255, 255, 255, 0.4);
    flex-direction: row-reverse;
    .icon {
      + .text {
        margin: 0 10px 0 0;
      }
    }
  }
`

const ListBenefits = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 900;

  li {
    padding-left: 50px;
    vertical-align: middle;
    margin: 0;
    position: relative;
    font-size: 16px;
    line-height: 40px;
    ${BreakpointUp.sm`	
            font-size: 22px;
        `}
    + li {
      margin-top: 20px;
    }
    &:before {
      content: url(${ListWhiteArrow});
      width: 30px;
      height: 30px;
      border-radius: 50%;
      box-shadow: 0 0 0px 6px rgb(223, 227, 239, 0.4);
      vertical-align: middle;
      background: rgba(0, 0, 0, 0.2);
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 6px;
      line-height: 0;
    }
  }
`
const ListBenefitsItem = styled.li`
  color: #fff;
`
const StepPlaceholderText = styled.h2`
  margin-bottom:20px;
  text-align:left;
  color:rgba(0,0,0,0.08);
  position: relative;
  font-size: 34px;
  line-height: 44px;
  top:30px;
  @media (min-width: 768px) {
    font-size: 58px;
    line-height: 68px;
    top:35px;
  }
  @media (min-width: 992px) {
    font-size: 62px;
    line-height: 72px;
  }
`
const StepWrap = styled.div`
  background-color: #fff;
  padding: 45px 0 90px 0;
  ${BreakpointUp.xl`
    padding:0 0 90px 0;
  `}
`
const StepTitle = styled.strong`
  display: block;
  color: #000;
  font-family: "Heebo", sans-serif;
  font-weight: 900;
  margin-bottom: 0;
  text-align: right;
  position: relative;
  right: 40px;
  z-index: 1;
  top: 0;
  font-size: 40px;
  line-height: 48px;
  ${BreakpointUp.md`
    font-size: 58px;
    line-height: 68px;
  `}
  ${BreakpointUp.lg`
    font-size: 62px;
    line-height: 72px;
  `}
  ${BreakpointUp.xl`
    top: -32px;
  `}
`
const StepGrid = styled.div`
  margin: 0;
  counter-reset: counter;
  padding: 0 0 10px;
  ${BreakpointUp.lg`
    padding:0 0 60px;    
  `}
  &:after {
    content: "";
    position: absolute;
    right: 65px;
    bottom: 0;
    width: 2px;
    background-color: #dde4f0;
    top: 50px;
    ${BreakpointUp.xl`	
      top:0;
    `}
  }
`
const StepItem = styled.div`
  padding: 0;
  position: relative;
  margin: 70px 0;
  ${BreakpointUp.md`
        margin:0 0 40px;
    `}
  &:after,  &:before {
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    right: 25px;
    transform: none;
    top: -60px;
    ${BreakpointUp.md`
            top:50%;
            transform:translateY(-50%);
        `}
    ${BreakpointUp.xl`	
            width: 100px;
            height: 100px;
            right: 0;
        `}
  }
  &:after {
    content: "";
    border-radius: 50%;
    background-color: #fff;
    z-index: 1;
    border: 10px solid #f2f4f9;
    ${BreakpointUp.xl`	
            border-width: 20px;
        `}
  }
  &:before {
    font-weight: 900;
    color: #fc0002;
    counter-increment: counter;
    content: counter(counter);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    font-size: 26px;
    ${BreakpointUp.xl`	
            font-size: 48px;
        `}
  }
`

const CardStep = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  &:before {
    content: "";
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    border: 1px solid #dde4f0;
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.12);
    z-index: 1;
    width: 100%;
    right: 0;
    ${BreakpointUp.md`	
            width: 75%;
            right: 10%;
            margin: 0;
        `}
  }
  &:after {
    content: "";
    background: #dde4f0;
    width: 40px;
    position: absolute;
    top: 50%;
    bottom: 0;
    height: 2px;
    right: 70px;
    ${BreakpointUp.xl`		
            right: 100px;
        `}
  }
`
const CardStepFigure = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  ${BreakpointUp.md`		
        padding:50px 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
  figure {
    position: relative;
    margin: 0;
  }
`
const CardStepBody = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 15px 30px;
  ${BreakpointUp.md`		
    padding:30px;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
	`}
`
const CardStepTitle = styled.h2`
  margin: 0 0 20px;
  font-size: 24px;
  line-height: 34px;
  ${BreakpointUp.md`		
    font-size: 28px;
    line-height: 38px;
  `}
  ${BreakpointUp.lg`		
    font-size: 32px;
    line-height: 42px;
  `}
`
const CardStepText = styled.div`
  ul {
    font-weight: 700;
    li {
      padding-left: 40px;
      vertical-align: middle;
      margin: 0;
      line-height: 26px;
      position: relative;
      + li {
        margin-top: 15px;
      }
      &:before {
        content: url(${ListArrow});
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow: 0 0 0px 6px rgba(23, 27, 39, 0.04);
        vertical-align: middle;
        background: #fff;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }
`
const HelpBlock = styled.div`
  background-color: #f2f4f9;
  color: #000;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
  align-items: center;
  line-height: 30px;
  font-size: 18px;
  ${BreakpointUp.lg`		  
        font-size:20px;
    `}
`
const LoanWrap = styled.div`
  .calc {
    background-color:rgba(10 91 146 0.7);
    ${BreakpointUp.xl`	      
        padding:40px;
    `}
  }
`
const LoanBgImage = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  opacity: 0.15;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const BreadCrumbWrap = styled.div`
    display: flex;
    align-items: center;
    background: white;
    width: fit-content;
    margin-left: 0;
    padding: 0 15px;
`
const ProductItem = styled.div`
  position: relative;
  width: 100%;
  max-width:1200px;
  padding-right: 15px;
  padding-left: 15px; 
  margin: auto;
  margin-bottom:60px;
  &:after{	
      display:none;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background: #fff;
      z-index: 0;
      width: 460px;
      height: 460px;
      border: 60px solid rgba(221,228,240,0.5);
      @media (min-width: 1600px){
        display:block;
        left: calc(100% - 350px);
      }
      @media (min-width: 1740px){
        border: 80px solid rgba(221,228,240,0.5);
        left: calc(100% - 290px);
      }
  }
`
const FinancingTemplate = ({ data, location }) => {
  const pageData = data.contentfulFinancingPage

  const Scrollify = () =>
    document
      .querySelector("#financing")
      .scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })

  const [isVisibleApply, setisVisibleApply] = useState(false)
  const [isVisibleApplyFinance, setisVisibleApplyFinance] = useState(false)
  const [isVisibleQuote, setQuoteVisible] = useState(false)

  const showApply = () => {
    setisVisibleApply(true)
    if (typeof window !== `undefined`) {
      document.body.classList.add("modal-open")
    }
  }
  const onClosePopup = () => {
    setisVisibleApply(false)
    if (typeof window !== `undefined`) {
      document.body.classList.remove("modal-open")
    }
  }

  const showApplyFinance = () => {
    setisVisibleApplyFinance(true)
    if (typeof window !== `undefined`) {
      document.body.classList.add("modal-open")
    }
  }
  const onCloseFinancePopup = () => {
    setisVisibleApplyFinance(false)
    if (typeof window !== `undefined`) {
      document.body.classList.remove("modal-open")
    }
  }


  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <BreadCrumbWrap>
            <Link to="/">Home / </Link>
            <span>{ pageData.financingProcess ? " Financing" : " Rent-To-Own"}</span>
          </BreadCrumbWrap>
        </div>
      </BreadCrumb>
      <ApplyPopup isVisible={isVisibleApply} onClose={onClosePopup} location={location} />
      <ApplyPopupFinancingForm isVisible={isVisibleApplyFinance} onClose={onCloseFinancePopup} location={location} />
      <Section pt="0" pb="0" xpt="60px" xpb="60px" bgColor="#fff">
        <BackgroundImage>
          <GatsbyImage
            image={pageData.heroImage.gatsbyImageData}
            alt={pageData.heroImage.title}
          />
        </BackgroundImage>

        <div className="container">
          <SectionWhite>
            <MainPanel>
              <LeftPannel>
                <MarkdownContent>
                  <h1>{pageData.heroTitle}</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.heroDescription.childMarkdownRemark.html,
                    }}
                  />
                  {pageData.financingProcess ? (
                    <button type="button" onClick={Scrollify}>
                      <DefaultLinkButton
                        icon={<ArrowDownIcon />}
                        text="Learn more about our application process below…"
                      />
                    </button>
                  ) : (
                    <></>
                  )}
                </MarkdownContent>
              </LeftPannel>
              <RightPannel>
                <div className="isSticky">
                {pageData.financingProcess ? (
                  <CardPrimary>
                    <CardPrimaryHeading>
                      <CardPrimaryTitle>
                        {pageData.heroFeatureTitle}
                      </CardPrimaryTitle>
                    </CardPrimaryHeading>
                    <CardPrimaryBody>
                      <ListBenefits>
                        {pageData.heroFeatures.map(item => (
                          <ListBenefitsItem key={v4()}>
                            {item.content}
                          </ListBenefitsItem>
                        ))}
                      </ListBenefits>
                    </CardPrimaryBody>
                    
                    <CardPrimaryFooter>
                      <button                        
                        className="quote"
                        onClick={showApplyFinance}
                        onKeyDown={showApplyFinance}
                      >
                        <PrimaryButton
                          icon={<RightLineArrowIcon />}
                          text="Apply Now For Financing"
                        />
                      </button>
                    
                    </CardPrimaryFooter>
                  </CardPrimary>
                    ) : (
                      <CustomQuote location={location}/>
                    )}
                </div>
                      {/* <button                        
                        className="quote"
                        onClick={showApply}
                        onKeyDown={showApply}
                      >
                        <PrimaryButton
                          icon={<RightLineArrowIcon />}
                          text="Apply Now For RTO"
                        />
                      </button> */}
              </RightPannel>
            </MainPanel>
          </SectionWhite>
        </div>
      </Section>
      {pageData.products && (
        <Section
          pt="110px"
          pb="110px"
          xpt="60px"
          xpb="60px"
          bgColor="#F2F4F9"
        >
          {pageData.products.map((item, i) => {
            return(
              <ProductItem className={`products-${i}`}>
                <ProductCard data={item} openQuoteModal={()=>setQuoteVisible(true)} />
              </ProductItem>
            )
          })}
        </Section>)}
      {pageData.financingProcess && (
        <Section
          pt="110px"
          pb="110px"
          xpt="60px"
          xpb="60px"
          bgColor="#F7F7F7"
          className="circle-left"
        >
          <div className="container">
            <SectionTitle data-shadowtext="Coast to Coast" mb="30px">Our Financing Program</SectionTitle>
            <MarkdownContent maxWidth="fit-content">
              <p>Coast to Coast Carports provides flexibility on interest rates through our exceptional financing program to ensure that your dream metal building, metal carport, garage, shed, commercial or industrial building remains affordable.</p>              
              <p>Our financing program provides a multitude of great features, including:</p>
              <ul className="col-count-md-two">
                <li>Consumers–Homeowners Only Enclosed Portable Storage Sheds <br /><span className="normal">Loan amounts up to $25,000*</span></li>
                <li>Affixed Metal Garages (Bolted to Concrete and Not Removable/Portable)<br /> <span className="normal">Loan amounts up to $50,000 or up to $100,000 case by case.</span></li>
                <li>Commercial–Business Use Only Metal Structures (Fixed or Portable)<br /> <span className="normal">Loan amounts up to $350,000 or up to $1,000,000 case by case.</span></li>
                <li>Affordable payment plans as low as 6.9% and up to 15-year terms.</li>
                <li>Low monthly payments</li>
                <li>Real and true credit decisions - no misleading qualifications</li>
                <li>Work with both prime and subprime credit scores (as low as 595)</li>
                <li>Payments start after delivery</li>
              </ul>
              <p>* You can include the cost of concrete, permits, delivery, installation, and the garage in the total loan amount.</p>
              <p>No matter what your financial situation looks like, you can trust Coast to Coast Carports to provide an affordable solution that fits your wants, needs, and desires. What are you waiting for? Give us a call at <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a> and apply for metal building financing today!</p>
            </MarkdownContent>
            <button
              className="quote"
              onClick={showApply}
              onKeyDown={showApply}
            >
              <PrimaryButton
                icon={<RightLineArrowIcon />}
                textBefore="Apply Now"
              />
            </button>
          </div>
        </Section>
      )}
      {pageData.financingProcess ? (
        <Section
          pt="60px"
          pb="0"
          xpt="30px"
          xpb="0"
          bgColor="#F2F4F9"
          id="financing"
        >
          <div className="container">
            <StepPlaceholderText>
              Financing Process
            </StepPlaceholderText>
          </div>
          <StepWrap>
            <div className="container">
              <StepTitle>How It Works</StepTitle>
              <StepGrid>
                <StepItem>
                  <CardStep className="card">
                    <CardStepFigure className="card-img">
                      <figure>
                        <StaticImage
                          src="../images/applying.jpg"
                          alt="applying"
                        />
                      </figure>
                    </CardStepFigure>
                    <CardStepBody className="card-body">
                      <CardStepTitle className="card-title">Applying</CardStepTitle>
                      <CardStepText>
                        <ul>
                          <li>Apply for financing via this online form.</li>
                          <li>Submit your application by phone [(866) 681-7846](tel:8666817846).</li>
                          <li>Applications are available through our dealers by fax or email.</li>
                        </ul>
                      </CardStepText>
                    </CardStepBody>
                  </CardStep>
                </StepItem>
                <StepItem>
                  <CardStep className="card">
                    <CardStepFigure className="card-img">
                      <figure>
                        <StaticImage
                          src="../images/approved.jpg"
                          alt="approved"
                        />
                      </figure>
                    </CardStepFigure>
                    <CardStepBody className="card-body">
                      <CardStepTitle className="card-title">
                        What you need to get approved
                      </CardStepTitle>
                      <CardStepText>
                        <ul>
                          <li>Attach a proposed order for a building (Speak with a sales rep or dealer)</li>
                          <li>10% down payment required</li>
                          <li>Can be for multiple buildings</li>
                          <li>Can be for just ‘part’ of a larger building</li>
                        </ul>
                      </CardStepText>
                    </CardStepBody>
                  </CardStep>
                </StepItem>
                <StepItem>
                  <CardStep className="card">
                    <CardStepFigure className="card-img">
                      <figure>
                        <StaticImage
                          src="../images/approval.jpg"
                          alt="approval"
                        />
                      </figure>
                    </CardStepFigure>
                    <CardStepBody className="card-body">
                      <CardStepTitle className="card-title">
                        Approval
                      </CardStepTitle>
                      <CardStepText>
                        <ul>
                          <li>Approval status is often available same day if submitted before 3:00 pm central time.</li>
                          <li>You will receive notice by phone or email. This will be followed by a mail notification.</li>
                        </ul>
                      </CardStepText>
                    </CardStepBody>
                  </CardStep>
                </StepItem>
                <StepItem>
                  <CardStep className="card">
                    <CardStepFigure className="card-img">
                      <figure>
                        <StaticImage
                          src="../images/finalizing.jpg"
                          alt="finalizing"
                        />
                      </figure>
                    </CardStepFigure>
                    <CardStepBody className="card-body">
                      <CardStepTitle className="card-title">
                        Finalizing
                      </CardStepTitle>
                      <CardStepText>
                        <p>Congratulations on being approved for financing! We are looking forward to proceeding with your order. However, we will need your approval.</p>
                        <ul>
                          <li>The term sheet will be sent back to you for your review and approval</li>
                          <li>The final order with Coast To Coast Carports must be completed</li>
                          <li>The 10% deposit on the building will be collected</li>
                        </ul>
                      </CardStepText>
                    </CardStepBody>
                  </CardStep>
                </StepItem>
                <StepItem>
                  <CardStep className="card">
                    <CardStepFigure className="card-img">
                      <figure>
                        <StaticImage
                          src="../images/payments.jpg"
                          alt="payments"
                        />
                      </figure>
                    </CardStepFigure>
                    <CardStepBody className="card-body">
                      <CardStepTitle className="card-title">
                        Payments
                      </CardStepTitle>
                      <CardStepText>
                        <ul>
                          <li>Payments are made monthly</li>
                          <li>Payments only begin “AFTER” your building has been completed.</li>
                          <li>Payment amounts will be determined by the amount borrowed, interest rate, & term of loan.</li>
                        </ul>
                      </CardStepText>
                    </CardStepBody>
                  </CardStep>
                </StepItem>
                <StepItem>
                  <CardStep className="card">
                    <CardStepFigure className="card-img">
                      <figure>
                        <StaticImage
                          src="../images/criteria.jpg"
                          alt="criteria"
                        />
                      </figure>
                    </CardStepFigure>
                    <CardStepBody className="card-body">
                      <CardStepTitle className="card-title">
                        Criteria
                      </CardStepTitle>
                      <CardStepText>
                        <ul>
                          <li>Credit Score of at least 595</li>
                          <li>Minimum of 10% Downpayment required</li>
                          <li>Homeowner</li>
                          <li>Copy of Driver’s License or State ID</li>
                          <li>Debt ratio in line with proposed payment</li>
                          <li>Verifiable income</li>
                        </ul>
                      </CardStepText>
                    </CardStepBody>
                  </CardStep>
                </StepItem>
              </StepGrid>
            </div>
            <HelpBlock>
              <div className="container">
                Thank You For Thinking Of Financing With Coast To Coast
                Carports. Complete Your Application Below To See If You Qualify.
                We Look Forward To Your Business.
              </div>
            </HelpBlock>
          </StepWrap>
        </Section>
      ) : (
        <></>
      )}
      {pageData.content ? (
        <Section pt="50px" pb="90px" xpt="15px" xpb="60px" bgColor="#fff">
          <div className="container">
            <MarkdownContentRto>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.content.childMarkdownRemark.html,
                }}
              />
            </MarkdownContentRto>
          </div>
        </Section>
      ) : (
        <></>
      )}

      <Section
        pt="120px"
        pb="100px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-right md"
      >
        <div className="container">
          <SectionShadowText bottom="60%">{pageData.applyFormLabel}</SectionShadowText>
          <SectionTitle mb="10px">{pageData.applyFormTitle}</SectionTitle>
          <SectionLead mb="40px" maxWidth="1100px">
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.applyFormDescription.childMarkdownRemark.html,
              }}
            />
          </SectionLead>
        </div>
        {pageData.multiStepForm ? (
          <ApplicationInfoForm location={location} />
        ) : (
          <div className="container mw-950">
            <PersonalInformationRtoForm states={data.allContentfulState.edges} location={location} />
          </div>
        )}
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#0B619B">
        <LoanBgImage>
          <StaticImage src="../images/finance-banner.jpg" alt="Loan" />
        </LoanBgImage>
        <div className="container">
          <SectionTitle textAlign="left" color="#fff">Calculate your Monthly Payment</SectionTitle>
          <LoanWrap><LoanCalculator /></LoanWrap>
        </div>
      </Section>
      <QuotePopup
        isVisible={isVisibleQuote}
        location={location}
        onClose={() => setQuoteVisible(false)}
      />
    </Layout>
  )
}

export default FinancingTemplate

export const pageQuery = graphql`
  query FinancingTemplateQuery($id: String!) {
    contentfulFinancingPage(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        title
        gatsbyImageData
      }
      heroFeatureTitle
      heroFeatures {
        content
      }
      financingProcess
      content {
        childMarkdownRemark {
          html
        }
      }
      multiStepForm
      applyFormLabel
      applyFormTitle
      applyFormDescription {
        childMarkdownRemark {
          html
        }
      }
      products {
        metaTitle
        metaDescription
        productSku
        productName
        url
        productDescription {
          childMarkdownRemark {
            html
          }
        }
        featuredProduct
        productCategory {
          name
          url
        }
        productSubCategories {
          name
          url
        }
        productRoofStyle {
          name
        }
        productApplications {
          name
        }
        productImages {
          title
          gatsbyImageData(quality: 100)
        }
        width
        height
        length
        price
        applicableForFinancing
        applicableForFreeDelivery
        applicableForRto
        otherDetails {
          childMarkdownRemark {
            html
          }
        }
        installationStates {
          name
          url
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
