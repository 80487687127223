import React from "react"
import styled from "styled-components"
import {  
  CustomValidationMessage,
  RequiredTriangle,
} from "../Section"

const CustomRequired = styled(CustomValidationMessage)`
  top: 50px;
  left: -20px;
  z-index: 10;
  color: red;
`

const ThirdStep = props => {
  const {
    mPyamentRequire,
    eNameRequire,
    occupationRequire,
    ePhoneRequire,
    timeEmployedRequire,
    mIncomeRequire,
  } = props

  if (props.currentStep !== 3) {
    // Prop: The current step
    return null
  } else {
    return (
      <div className="grid">
        <div className="grid-12">
          <div className="form-group-bordered">
            <input
              type="text"
              id="monthly_payment"
              className="form-control"
              onChange={props.handleChange}
              placeholder="Mortgage Payment per Month*"
              required
            />
            <CustomRequired display={mPyamentRequire ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </div>
        </div>
        <div className="grid-12">
          <div className="form-group-bordered">
            <input
              type="text"
              id="employer_name"
              className="form-control"
              onChange={props.handleChange}
              placeholder="Employer Name (if self or retired state so)*"
              required
            />
            <CustomRequired display={eNameRequire ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </div>
        </div>
        <div className="grid-12">
          <div className="form-group-bordered">
            <input
              type="text"
              id="occupation"
              className="form-control"
              onChange={props.handleChange}
              placeholder="Occupation*"
              required
            />
            <CustomRequired display={occupationRequire ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </div>
        </div>
        <div className="grid-12">
          <div className="form-group-bordered">
            <input
              type="tel"
              id="employer_phone_no"
              className="form-control"
              onChange={props.handleChange}
              pattern="[0-9]{10}"
              placeholder="Employer Phone Number(xxx-xxx-xxxx)*"
              required
            />
            <CustomRequired display={ePhoneRequire ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </div>
        </div>
        <div className="grid-12">
          <div className="form-group-bordered">
            <input
              type="text"
              id="ext"
              className="form-control"
              onChange={props.handleChange}
              placeholder="Ext"
              required
            />
          </div>
        </div>
        <div className="grid-12">
          <div className="form-group-bordered">
            <input
              type="text"
              id="time_employed"
              className="form-control"
              onChange={props.handleChange}
              placeholder="Time Employed*"
              required
            />
            <CustomRequired display={timeEmployedRequire ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </div>
        </div>
        <div className="grid-12">
          <div className="form-group-bordered">
            <input
              type="text"
              id="monthly_income"
              className="form-control"
              onChange={props.handleChange}
              placeholder="Monthly Employment Income*"
              required
            />
            <CustomRequired display={mIncomeRequire ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </div>
        </div>
        <div className="grid-12">
          <div className="form-group-bordered">
            <input
              type="text"
              id="other_monthly_income"
              className="form-control"
              onChange={props.handleChange}
              placeholder="Other Montlhy Income*"
              required
            />
          </div>
        </div>
        <div className="grid-12">
          <div className="form-group-bordered">
            <select
              id="co_application"
              className="form-control"
              onChange={props.handleChange}
            >
              <option value="">
                Is there a Co-Applicant?(must be in order to qualify)
              </option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
        </div>
      </div>
    )
  }
}

export default ThirdStep
