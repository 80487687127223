import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const DefaultLink = styled(BaseButton)`		
	padding-left:0 !important;
	padding-right:0 !important;
	color:#999;	
    font-size: 14px;
    font-weight: 500;
	& .icon{
		> svg{
			fill:#FC0002;
		}
	}
	&:hover{
		color:#000;
		& .icon{
			> svg{
				fill:#000;
			}
		}
	}
`

const DefaultLinkButton = (props) => {
	const { icon, text } = props;
	return(
		<DefaultLink className='btn'>
			<span className='text'>{text}</span><span className='icon'>{icon}</span>
		</DefaultLink>
	)
}

export default DefaultLinkButton