import React from 'react'
import styled from 'styled-components'
import { CustomValidationMessage, RequiredTriangle } from '../Section'

const CustomRequired = styled(CustomValidationMessage)`
  top: 50px;
  left: -20px;
  z-index: 10;
  color: red;
`

const SecondStep = ( props) => {
	const {
		streetAddressRequire,
		cityRequire,
		stateRequire,
		zipRequire,
	} = props
	if (props.currentStep !== 2) { // Prop: The current step
    return null
  } else {
		return (
			<div className="grid">
				<div className="grid-12">
					<div className="form-group-bordered">
					  <input type="text" id="street_address" className="form-control" onChange={props.handleChange} placeholder="Street Address*" required/>
					  <CustomRequired display={streetAddressRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
					</div>
				</div>
				<div className="grid-12">
					<div className="form-group-bordered">
					  <input type="text" id="street_address_2" className="form-control" onChange={props.handleChange} placeholder="Address Line 2" />
					</div>
				</div>
				<div className="grid-12">
					<div className="form-group-bordered">
					  <input type="text" id="city" className="form-control" placeholder='City*' onChange={props.handleChange} required/>
					  <CustomRequired display={cityRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
					</div>
				</div>
				<div className="grid-12">
					<div className="form-group-bordered">
					  <select id="state" className="form-control" onChange={props.handleChange} aria-label="state">
					  <option value="">State</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
					  </select>
					  <CustomRequired display={stateRequire ?"block": "none"}><RequiredTriangle /><span>Please select one state</span></CustomRequired>
					</div>                
				</div>
				<div className="grid-12">
					<div className="form-group-bordered">
					  <input type="zipcode" id="zipcode" className="form-control" placeholder='Zip Code*' onChange={props.handleChange} required/>
					  <CustomRequired display={zipRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
					</div>
				</div>
				
			</div>
		)
	}
}

export default SecondStep;