import React, { useState, useCallback, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { FormControlLabel } from "../Section"
import BreakpointUp from "../Media/BreakpointUp"
// import { RightLineArrowIcon } from "../Icons"
// import FormButton from "../Button/FormButton"

const Calculator = styled.div`    
    background-color:#0B619B;
    position:relative;
    padding:20px;
    ${BreakpointUp.xl`	      
        padding:40px;
    `}
    &:after{
        bottom: 100%;
        left:60px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(11, 97, 155, 0);
        border-bottom-color: #0B619B;
        border-width: 14px;
        margin-left: -14px;
    }
`  
const FormGrid = styled.div`	
    display:flex;
    margin:0 -10px;
    justify-content: space-between;
    align-items:center;
    flex-wrap: wrap;
    ${BreakpointUp.md`
        flex-wrap: nowrap;
    `}
    .form-action{
        .btn{
            border:6px solid #fff;
        }
    }
`
const FormTerm = styled.div`	    
    position: relative;
    width: 100%;
    padding:0 10px;
    ${BreakpointUp.sm`
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    `}
`

const FormAmount = styled.div`	    
    position: relative;
    width: 100%;
    padding:0 10px;
    ${BreakpointUp.sm`
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
    ${BreakpointUp.lg`
        flex: 0 0 50%;
        max-width: 50%;
    `}
`
const FormPayment = styled.div`	    
    position: relative;
    width: 100%;
    padding:0 10px;
    text-align: left;
    ${BreakpointUp.sm`
        text-align: right;
        flex: 0 0 25%;
        max-width: 25%;
    `}
`
const ListRadio = styled.div`	
    display:flex;
    flex-wrap:wrap;    
    margin:0 -3px;
  
`
const ListRadioItem = styled.div`	
    padding:0 3px;
    position:relative;
    input[type=radio]{
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        left: 0;
    }
    label {
        border:1px solid rgba(255,255,255,0.3);
        background-color:transparent;
        font-size:16px;
        font-weight:700;
        color:#fff;
        padding:15px;
        border-radius: 20px;
        display: inline-flex;
        min-width: 60px;
        justify-content: center;
    }
    input[type=radio]:checked {
        + label {
            font-weight: 400;
            background: rgba(0,0,0,0.2);
        }
    }
  
`
const LoanCalculator = () => {
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [term, setTerm] = useState(36)
  const [loan, setLoan] = useState(0)

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            loanPrice {
                apr_36,
                apr_48,
                apr_60
            }
          }
        }
      }
    `
  )
  
  const monthlyPyamentCallback = useCallback((e) => {
    const { value } = e.target
    if(value < 100000 ){
        setLoan(value)
        if (term === 36) {
          var price = (value/site.siteMetadata.loanPrice.apr_36).toFixed(2)
          setMonthlyPayment(price)
        }
        if (term === 48) {
          price = (value/site.siteMetadata.loanPrice.apr_48).toFixed(2)
          setMonthlyPayment(price)
        }
        if (term === 60) {
          price = (value/site.siteMetadata.loanPrice.apr_60).toFixed(2)
          setMonthlyPayment(price)
        } 
    } else {
        return true
    }
  }, [monthlyPayment, setMonthlyPayment]);

  useEffect(() => {
    if (term === 36) {
      var value = (loan/site.siteMetadata.loanPrice.apr_36).toFixed(2)
      setMonthlyPayment(value)
    }
    if (term === 48) {
      value = (loan/site.siteMetadata.loanPrice.apr_48).toFixed(2)
      setMonthlyPayment(value)
    }
    if (term === 60) {
      value = (loan/site.siteMetadata.loanPrice.apr_60).toFixed(2)
      setMonthlyPayment(value)
    } 
  })
  return (
    <Calculator className="calc">        
      <form id="calculatorform">
        <FormGrid>
            <FormTerm>
                <div className="form-group">
                    <FormControlLabel className="control-label">Term (in months)</FormControlLabel>
                    <ListRadio>
                        <ListRadioItem onClick={()=>setTerm(36)}>
                            <input id="term1" name="term" type="radio" value="36" defaultChecked  />
                            <label htmlFor="term1">36</label>
                        </ListRadioItem>
                        <ListRadioItem onClick={()=>setTerm(48)}>
                            <input id="term2" name="term" type="radio" value="48" />
                            <label htmlFor="term2">48</label>
                        </ListRadioItem>
                        <ListRadioItem onClick={()=>setTerm(60)}>
                            <input id="term3" name="term" type="radio" value="60" />
                            <label htmlFor="term3">60</label>
                        </ListRadioItem>
                    </ListRadio>
                </div>
            </FormTerm>
            <FormAmount>
                <div className="form-group">
                    <FormControlLabel className="control-label">Enter Loan Amount</FormControlLabel>
                    <input type="number" id="loan_amount" max="99999" className="form-control" placeholder="Enter Loan Amount" aria-label="loan amount" onChange={monthlyPyamentCallback}/>
                </div>                        
            </FormAmount>
            <FormPayment>
                <div className="form-group">
                    <FormControlLabel className="control-label">Monthly Payment</FormControlLabel>
                    <div className="form-control-text">${monthlyPayment}</div>
                </div>                        
            </FormPayment>
            {/* <FormCol>
                <div className="form-action">
                    <FormButton icon={<RightLineArrowIcon />} text="Apply Now" />
                </div>          
            </FormCol> */}
        </FormGrid>
      </form> 
    </Calculator> 
  )
}

export default LoanCalculator
